<template>
  <div class="content">

    <div class="container inside-container">
      <h1 class="b-title-sub" v-html="trainings.title">

      </h1>
      <div class="cal-date">
        {{ getStringDate(trainings.date) }}
      </div>

      <div class="video-slider" v-if="currentPageTraining">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="video-block">
                <youtube :video-id="getYoutubeId(currentPageTraining.link)" ref="youtube" class="img-responsive" />
              </div>
            </div>
          </div>
        </div>
        <!-- Add Arrows -->
        <div class="swiper-button-next next" @click="pagePlus"></div>
        <div class="swiper-button-prev prev" @click="pageMinus"></div>
      </div>

      <div class="content-text text-size-middle">
        <div v-html="trainings.description" />
        <div class="speakers-block">
          <div class="event-row clearfix">
            <div class="event-name">Спикеры</div>
            <div class="event-desc">
              <div class="speaker" v-for="speaker in trainings.speakers" v-bind:key="speaker.id">
                <div class="photo">
                  <img :src="speaker.photo">
                </div>
                <div class="desc">
                  <div class="name">{{ speaker.name }}</div>
                  <div class="text">{{ speaker.speciality }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="shedule-link">
          <div class="instructions">
            <a :href="trainings.pdf_file" class="instruction-link" v-if="trainings.pdf_file">Полезный документ</a>
          </div>
        </div>

        <div v-if="trainings.connected_material_id" class="webinar-controls">
          <a :href="trainings.connected_material_id" class="button button-arrow"><span>Следующая часть</span></a>
        </div>

        <h1 class="b-title-sub mid-title" >
          Смотрите так же
        </h1>

        <TevaSlider />

      </div>

    </div>

  </div>
</template>

<script>
import moment from 'moment'
import { getIdFromUrl } from 'vue-youtube'
import TevaSlider from '@/components/TevaSlider'
export default {
  name: 'TrainingTemplate',
  title: 'Вебинар',
  components: {
    TevaSlider
  },
  data () {
    return {
      loading: false,
      currentPage: 0
    }
  },
  computed: {
    trainings () {
      return this.$store.getters.getTraining
    },
    currentPageTraining () {
      return this.trainings.video_links[this.currentPage]
    }
  },
  watch: {
    async '$route.params.id' (value) {
      await this.fetchData()
    }
  },
  async mounted () {
    await this.fetchData()
  },
  methods: {
    async fetchData () {
      this.loading = true
      await this.$store
        .dispatch('fetchTraining', this.$route.params.id)
        .then(({ data }) => {
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    getStringDate (date) {
      const momentDate = moment(date)
      return momentDate.locale('ru').format('DD MMMM YYYY')
    },
    getYoutubeId (youtubeUrl) {
      return getIdFromUrl(youtubeUrl)
    },
    pagePlus () {
      if (this.currentPage === this.trainings.video_links.length - 1) {
        return
      }
      this.currentPage++
    },
    pageMinus () {
      if (this.currentPage === 0) {
        return
      }
      this.currentPage--
    }
  }
}
</script>
